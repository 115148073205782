import React from "react";
import { motion } from "framer-motion";
import "bootstrap/dist/css/bootstrap.min.css";

const CircleProgressBar = ({ percentage, size = 40 }) => {
  const circleRadius = size / 2 - 10;
  const circleCircumference = 2 * Math.PI * circleRadius;

  const strokeDashoffset =
    circleCircumference - (percentage / 100) * circleCircumference;

  return (
    <div
      className="me-3 position-relative"
      style={{ width: `${size}px`, height: `${size}px` }}
    >
      <svg
        width={size}
        height={size}
        className="position-absolute"
        style={{ transform: "rotate(-90deg)" }}
      >
        <circle
          cx={size / 2}
          cy={size / 2}
          r={circleRadius}
          fill="transparent"
          stroke="#e6e6e6"
          strokeWidth="5"
        />
        <motion.circle
          cx={size / 2}
          cy={size / 2}
          r={circleRadius}
          fill="transparent"
          stroke="#007bff"
          strokeWidth="5"
          strokeDasharray={circleCircumference}
          strokeDashoffset={circleCircumference}
          animate={{ strokeDashoffset }}
          transition={{ duration: 1, ease: "easeInOut" }}
        />
      </svg>
    </div>
  );
};

export default CircleProgressBar;
