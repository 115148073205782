import axios from "axios";
import React, { useState } from "react";
import Modal from "react-modal";
import Icon from "@mdi/react";
import { mdiDownload, mdiAlertCircle } from "@mdi/js";

const ReportAndDownloadButton = ({ data, images, index }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [url] = useState("https://api-share-test.timelapserobot.com/v1/report");
  const [inputValue, setInputValue] = useState("");

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const dataSubmit = {
      reason: inputValue,
      shareId: data.projectCameras[0].shareId,
      image: images[index].timestamp,
    };
    try {
      const response = await axios.post(url, dataSubmit);
      console.log("Response from backend:", response.data);
      closeModal();
    } catch (error) {
      console.error("Error submitting data:", error);
    }
    closeModal();
  };

  const downloadImage = () => {
    const currentImageUrl = data.baseUrl + images[index].downloadUrl;
    window.open(currentImageUrl, "_blank");
  };

  return (
    <div className="row justify-content-center text-right text-lg-right mr-1 mr-lg-0 pl-5">
      <div className="col-lg-6 col-12">
        <div className="btn-group">
          <button
            className="reportButton btn-primary"
            style={{ marginRight: "5px", fontFamily: "nunito" }}
            onClick={openModal}
          >
            <Icon path={mdiAlertCircle} size={1} />
          </button>
          <button
            className="downloadButton btn-primary"
            onClick={downloadImage}
          >
            <Icon path={mdiDownload} size={1} />
          </button>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        appElement={document.getElementById("root")}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: "20px",
          },
        }}
      >
        <h2>Report</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Reason</label>
            <input
              type="text"
              name="Reason:"
              value={inputValue}
              onChange={handleInputChange}
              required
              style={{ marginLeft: "5px" }}
            />
          </div>
          <button
            id="submit"
            type="submit"
            style={{
              marginTop: "5px",
              color: "white",
              fontFamily: "nunito",
              cursor: "pointer",
            }}
          >
            Submit
          </button>
        </form>
        <button
          id="close"
          onClick={closeModal}
          style={{
            marginTop: "5px",
            color: "white",
            fontFamily: "nunito",
            cursor: "pointer",
          }}
        >
          Close
        </button>
      </Modal>
    </div>
  );
};
export default ReportAndDownloadButton;
