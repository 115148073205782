import React from "react";
import { motion } from "framer-motion";
import { Icon } from "@mdi/react";
import { mdiPlay, mdiStop } from "@mdi/js";
import ImageLoading from "./ImageLoading";

const PlayButtonWithSlider = ({
  playImages,
  intervalId,
  images,
  index,
  handleSliderChange,
  percentage,
}) => {
  return (
    <div className="row justify-content-center">
      <div className="col-lg-6 col-12 text-center mb-1 d-flex align-items-center">
        <div className="me-3">
          {percentage < 100 ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <ImageLoading percentage={percentage} />
            </motion.div>
          ) : (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <button
                id="playButton"
                onClick={playImages}
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                <Icon path={intervalId ? mdiStop : mdiPlay} size={1} />
              </button>
            </motion.div>
          )}
        </div>
        <div className="flex-grow-1">
          <input
            type="range"
            min="0"
            max={images.length - 1}
            value={index}
            onChange={handleSliderChange}
            className="slider"
            style={{
              color: "#0d97be",
              direction: "rtl",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PlayButtonWithSlider;
