import { useState, useEffect } from "react";
import axios from "axios";
import CryptoJS from "crypto-js";

const useFetchData = (id) => {
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [percentage, setPercentage] = useState(0); // Track percentage
  const [isEmpty, setIsEmpty] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [falsePassword, setFalsePassword] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  const fetchImages = async (password) => {
    let currentLastEvaluatedKey = null; // Local variable to manage the key
    let currentIteration = 0; // Local iteration counter
    let totalPages = 0; // Total pages to calculate once

    try {
      while (true) {
        const response = await axios.get(`${apiUrl}/${id}`, {
          params: {
            password: password,
            lastEvaluatedKey: currentLastEvaluatedKey,
            limit: 100,
          },
        });
        setLoading(false);
        setImages((prevData) => [...prevData, ...response.data.images]);
        // On the first iteration, calculate total pages
        if (currentIteration === 0) {
          setData(response.data);
          const totalImageCount = response.data.totalImageCount || 0;
          totalPages = Math.ceil(totalImageCount / 100);
          console.log(
            `Total images: ${totalImageCount}, Total pages: ${totalPages}`
          );
        }

        console.log(`Fetching page ${currentIteration + 1}`);
        // Process the data as needed here
        currentLastEvaluatedKey = response.data.lastEvaluatedKey;

        const calculatedPercentage = Math.round(
          ((currentIteration + 1) / totalPages) * 100
        );
        setPercentage(calculatedPercentage);

        // Increment the local iteration counter
        currentIteration += 1;

        // Break the loop if there are no more keys or iterations reach total pages
        if (!currentLastEvaluatedKey || currentIteration >= totalPages) {
          console.log("All images have been fetched.");
          break;
        }
      }
    } catch (error) {
      if (error.response.status === 403) {
        setIsModalOpen(true);
      } else {
        setError(error);
      }
      console.error("Error fetching images:", error);
    }
  };

  useEffect(() => {
    fetchImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  /*const fetchData = async (passwordHash = null) => {
    try {
      const params = passwordHash ? { password: passwordHash } : {};
      const response = await axios.get(url, { params });
  */
  const sendPassword = (password) => {
    const passwordHash = CryptoJS.SHA256(password).toString();
    sessionStorage.setItem("passwordHash", passwordHash);
    //sendPasswordToApi(passwordHash); // Refetch the data with the hashed password
    fetchImages(passwordHash);
    setIsModalOpen(false); // Close modal after submitting
  };

  useEffect(() => {
    /*if (data?.images.length === 0) {
      setIsEmpty(true);
    }*/
    if (data?.headerColor === undefined || data?.headerColor !== null) {
      document.documentElement.style.setProperty(
        "--button-background-color",
        data?.headerColor
      );
    }
  }, [data]);

  return {
    loading,
    data,
    images,
    error,
    isEmpty,
    isModalOpen,
    setIsModalOpen,
    sendPassword,
    falsePassword,
    percentage,
  };
};
export default useFetchData;
